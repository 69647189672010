//<ПЕРЕМЕННЫЕ>===========================================================================================================
@import "_variables.scss";
//</ПЕРЕМЕННЫЕ>===========================================================================================================
@import "slick";
//====================================================================================================
.slider-home {
  margin-top: 20px;
  .slide-html {
    position: relative;
    button {
      position: absolute;
      bottom: 55px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: $color-primary;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    z-index: $z-index-slick-arrow;
  }
  .slick-prev {
    left: 22px;
  }
  .slick-next {
    right: 22px;
  }
  @media (max-width: $mdMobile) {
    .slide-html {
      button {
        display: none;
      }
    }
    .slick-arrow {
      display: none;
    }
  }
}

#homepage-banner {
  background-image: url(/image/bg_interlace_80.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 440px;
  display: flex;
  padding: 20px;
  img {
    max-height: 100%;
    height: 400px;
    filter: drop-shadow(0 0 0.75rem #000);
  }
  .image {
    flex: 1 1 40%;
    text-align: center;
  }
  .text {
    flex: 1 1 60%;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .text__header {
    font-size: 52px;
    line-height: 1;
    font-weight: 700;
    text-shadow: 0 0 6px black, 0 0 3px black, 0 0 2px black, 0 0 2px black;
  }
  .text__inscription {
    font-size: 24px;
    line-height: 1;
    text-shadow: 0 0 6px black, 0 0 3px black, 0 0 2px black, 0 0 2px black;
    margin: 40px 0;
  }
  .text__product {
  }

  @media (max-width: $mdLaptop) {
    .text__header {
      font-size: 36px;
    }
    .text__inscription {
      font-size: 22px;
    }
  }
  @media (max-width: $mdMobile) {
    flex-direction: column;
    height: auto;
    img {
      height: 250px;
    }
    .text__header {
      font-size: 24px;
      margin: 25px 0;
    }
    .text__inscription {
      font-size: 18px;
      display: none;
    }
  }
}

/*#homepage-slider {
  width: 100%;
  height: 444px;
  overflow: hidden;
  @media (max-width: $mdHdPlus) {
    height: 362px;
  }
  @media (max-width: $mdHd) {
    height: 337px;
  }
  @media (max-width: $mdLaptop) {
    height: 264px;
  }
  @media (max-width: $mdTablet) {
    height: 35vw;
  }
  @media (max-width: $mdMobile) {
    height: 80vw;
  }

  .slick-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    li {
      display: inline-block;
      margin: 5px;
      width: 15px;
      height: 15px;
      border: 2px solid $color-primary;
      border-radius: 100%;
      button {
        display: none;
      }
      &.slick-active {
        background-color: $color-primary;
      }
    }
  }
}*/

//====================================================================================================

.trigger-trusts {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  .trigger-trust {
    display: flex;
    width: 100%;
    &__pic {
      flex: 0 0 20%;
      margin-left: $margin-horizontal;
      margin-right: $margin-horizontal;
    }
    &__info {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__header {
      font-size: 18px;
      line-height: 20px;
      font-weight: 400;
    }
    &__sub {
      font-size: 13px;
      line-height: 20px;
      color: $color-text;
    }
  }
  @media (max-width: $mdTablet) {
    flex-direction: row;
    flex-wrap: wrap;
    .trigger-trust {
      margin-top: 15px;
      flex: 0 0 40%;
      &__info {
        justify-content: flex-start;
      }
      &__header {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
      }
      &__sub {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
  @media (max-width: $mdMobile) {
    display: none;
  }
}

.home-banner {
  text-align: center;
}

@media (max-width: $mdMobile) {
  .home-banner {
    display: none;
  }
}

.home-brands__carousel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  //height: 158px;
  //overflow: hidden;
  //position: relative;
  padding-top: 40px;
  //padding-bottom: 40px;
  /*button {
    font-size: 36px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 800;
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }*/
  li {
    text-align: center;
    a {
      display: inline-block;
    }
  }
  @media (max-width: $mdTablet) {
    display: none;
  }
}

.product-carousel,
.safe,
.viewed {
  margin-top: 40px;
}

.countdown {
  font-family: sans-serif;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 26px;
  padding-top: 15px;
  & > div {
    padding: 5px 0;
    width: 45px;
    border-radius: 5px;
    background: #eeeeee;
    display: inline-block;
  }

  & div > span {
    display: inline-block;
  }

  .smalltext {
    font-size: 10px;
    color: $color-text;
  }
}
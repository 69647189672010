/*

@media (max-width: $mdHd) {
}
@media (max-width: $mdLaptop) {
}
@media (max-width: $mdTablet) {
}
@media (max-width: $mdMobile) {
}

*/

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slider-home {
  margin-top: 20px;
}

.slider-home .slide-html {
  position: relative;
}

.slider-home .slide-html button {
  position: absolute;
  bottom: 55px;
  left: 50%;
  transform: translateX(-50%);
}

.slider-home .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ec1b23;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  z-index: 200;
}

.slider-home .slick-prev {
  left: 22px;
}

.slider-home .slick-next {
  right: 22px;
}

#homepage-banner {
  background-image: url(/image/bg_interlace_80.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 440px;
  display: flex;
  padding: 20px;
}

#homepage-banner img {
  max-height: 100%;
  height: 400px;
  filter: drop-shadow(0 0 0.75rem #000);
}

#homepage-banner .image {
  flex: 1 1 40%;
  text-align: center;
}

#homepage-banner .text {
  flex: 1 1 60%;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#homepage-banner .text__header {
  font-size: 52px;
  line-height: 1;
  font-weight: 700;
  text-shadow: 0 0 6px black, 0 0 3px black, 0 0 2px black, 0 0 2px black;
}

#homepage-banner .text__inscription {
  font-size: 24px;
  line-height: 1;
  text-shadow: 0 0 6px black, 0 0 3px black, 0 0 2px black, 0 0 2px black;
  margin: 40px 0;
}

/*#homepage-slider {
  width: 100%;
  height: 444px;
  overflow: hidden;
  @media (max-width: $mdHdPlus) {
    height: 362px;
  }
  @media (max-width: $mdHd) {
    height: 337px;
  }
  @media (max-width: $mdLaptop) {
    height: 264px;
  }
  @media (max-width: $mdTablet) {
    height: 35vw;
  }
  @media (max-width: $mdMobile) {
    height: 80vw;
  }

  .slick-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    li {
      display: inline-block;
      margin: 5px;
      width: 15px;
      height: 15px;
      border: 2px solid $color-primary;
      border-radius: 100%;
      button {
        display: none;
      }
      &.slick-active {
        background-color: $color-primary;
      }
    }
  }
}*/

.trigger-trusts {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.trigger-trusts .trigger-trust {
  display: flex;
  width: 100%;
}

.trigger-trusts .trigger-trust__pic {
  flex: 0 0 20%;
  margin-left: 10px;
  margin-right: 10px;
}

.trigger-trusts .trigger-trust__info {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trigger-trusts .trigger-trust__header {
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
}

.trigger-trusts .trigger-trust__sub {
  font-size: 13px;
  line-height: 20px;
  color: #333;
}

.home-banner {
  text-align: center;
}

.home-brands__carousel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  /*button {
    font-size: 36px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 800;
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }*/
}

.home-brands__carousel li {
  text-align: center;
}

.home-brands__carousel li a {
  display: inline-block;
}

.product-carousel,
.safe,
.viewed {
  margin-top: 40px;
}

.countdown {
  font-family: sans-serif;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 26px;
  padding-top: 15px;
}

.countdown > div {
  padding: 5px 0;
  width: 45px;
  border-radius: 5px;
  background: #eeeeee;
  display: inline-block;
}

.countdown div > span {
  display: inline-block;
}

.countdown .smalltext {
  font-size: 10px;
  color: #333;
}

@media (max-width: 1240px) {
  #homepage-banner .text__header {
    font-size: 36px;
  }

  #homepage-banner .text__inscription {
    font-size: 22px;
  }
}

@media (max-width: 992px) {
  .trigger-trusts {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .trigger-trusts .trigger-trust {
    margin-top: 15px;
    flex: 0 0 40%;
  }

  .trigger-trusts .trigger-trust__info {
    justify-content: flex-start;
  }

  .trigger-trusts .trigger-trust__header {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }

  .trigger-trusts .trigger-trust__sub {
    font-size: 12px;
    line-height: 20px;
  }

  .home-brands__carousel {
    display: none;
  }
}

@media (max-width: 672px) {
  .slider-home .slide-html button {
    display: none;
  }

  .slider-home .slick-arrow {
    display: none;
  }

  #homepage-banner {
    flex-direction: column;
    height: auto;
  }

  #homepage-banner img {
    height: 250px;
  }

  #homepage-banner .text__header {
    font-size: 24px;
    margin: 25px 0;
  }

  #homepage-banner .text__inscription {
    font-size: 18px;
    display: none;
  }

  .trigger-trusts {
    display: none;
  }

  .home-banner {
    display: none;
  }
}